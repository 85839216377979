import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import { Typography, Box, SvgIcon } from "@lib/ui/core"
import Container from "@layouts/Container"
import Layout from "@layouts/MainLayout"
import BannerImage from "@assets/images/banner.png"
import Imgix from "react-imgix"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"

const PaymentSuccess = () => {
  const [time, setTime] = useState(5)

  useEffect(() => {
    const timeToRedirct = setInterval(() => {
      setTime((time) => time - 1)
    }, 1000)
    const timeout = setTimeout(() => {
      navigate("/exercise/select", { replace: true })
    }, 5000)
    return () => {
      clearInterval(timeToRedirct)
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Layout bgcolor="#14a1e9">
      <Box display="flex" width="100%">
        <Container>
          <Box my={2} p={2} borderRadius="32px">
            <Typography variant="h1" color="textSecondary">
              <SvgIcon component={DoneOutlineIcon} fontSize="4.5rem" /> 付款成功
            </Typography>
            <Typography variant="h4" color="textSecondary">
              {time > 0
                ? "將會在" + time + "秒後重新導向..."
                : "即將重新導向..."}
            </Typography>
          </Box>
          <Box>
            <Imgix src={BannerImage} width="100%" />
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default PaymentSuccess
